@media (min-width:1200px) {
	body {
		&.admin-bar {
			#page {
				&.vertical-header {
					#site-header-wrapper {
						padding:0 0 0 30px;
						.container {
							padding:0;
						}
					}
				}
			}
		}
	}
	
	#page.vertical-header {
		transition:all 1s ease;
		#primary-menu.no-responsive {
			> li {
				padding:0;
				margin:0;
				> a {
					color:fadeout(#fff, 50%);
					padding:20px 0 0;
					&:after {
						border-color:fadeout(#fff, 75%);
					}
				}
				&.menu-item-active {
					> a {
						color:#fff;
						&:after {
							border-color:fadeout(#fff, 25%);
							width:200px;
						}
					}
				}
			}
		}
		#site-header {
			.site-title {
				margin:0;
			}
		}
		#site-header-wrapper {
			padding:0 0 0 30px;
			.container {
				padding:0;
			}
		}
		.vertical-toggle {
			display:block;
			top:20px;
			left:220px;
			z-index:100;
			position:fixed;
			> span {
				background-color:#fff;
				&.menu-line-1 {
					transform: rotate(45deg);
					top: 10px;
				}
				&.menu-line-2 {
					opacity:0;
				}
				&.menu-line-3 {
					transform: rotate(-45deg);
					top: 10px;
				}
			}
		}
	}
}

@media (max-width:1199px) {
	#page.vertical-header #site-header-wrapper {
		width:auto;
	}
}



.header-main.logo-position-left.header-layout-vertical.header-style-vertical {
    margin:0;
}

#site-header {
    .site-title {
        a {
            img { margin:0; }
        }
    }
    .menu-item-logo {
        a {
            img { margin:0; }
        }
    }
}

#site-header,
#site-header-wrapper,
.header-layout-fullwidth_hamburger #primary-menu {
    background-color:#000;
}

.block-content {
    background-color:#222;
}

.menuHidden {
    #page.vertical-header {
        padding-left:0;
        #site-header-wrapper {
            padding:0;
	        margin-left:-270px;
            &.vertical-active {
                .vertical-toggle {
                    left:20px;
                    > span {
                        &.menu-line-1 {
                            transform: rotate(0deg);
                            top: 2px;
                         }
                         &.menu-line-2 {
                             opacity:1;
                         }
                         &.menu-line-3 {
                            transform: rotate(0deg);
                            top: 18px;
                         }
                    }
                }
            }
        }
    }
}

.headerFlexCenter {
	min-height:30vh;
	> div {
		padding:0!important;
	}
	.vc_column-inner {
		display:flex;
		align-items:center;
		justify-content: center;
	}
	.pagettl {
		position:static;
		text-align:center;
		h2 {
			margin-bottom:0;
		}
	}
}

#main {
	min-height: 100vh;
	background-color: #222;
}

#page.vertical-header {
	padding:0 0 0 270px;
}

#page.vertical-header #site-header-wrapper {
	margin:0;
}
#page.vertical-header #site-header-wrapper .header-main {
	visibility: visible;
}

.fullcoveredvideo {
	video {
		margin: auto!important;
		top: -9999px!important;
		left: -9999px!important;
		right: -9999px!important;
		bottom: -9999px!important;
		height: auto!important;
		width: auto!important;
		min-height:100%!important;
		min-width: 100%!important;
	}
}

.gem-slideshow {
    min-height:100vh;
    .forcefullwidth_wrapper_tp_banner {
        position:fixed!important;
        left:0;
        right:0;
        top:0;
        bottom:0;
    }
    .tp-revslider-mainul {
        > li {
            display:flex;
            align-items:center;
            justify-content:center;
            flex-flow:column wrap;
            transition:left 1s ease;
            width: auto!important;
            right: 0!important;
            @media (min-width:978px) {
                left:271px!important;
            }
            .tp-parallax-wrap {
                min-width:100%;
                position:static!important;
                .tp-loop-wrap {
                    position:static!important;
                    text-align:center;
                    .tp-mask-wrap {
                        display:flex!important;
                        justify-content:center;
                        position:static!important;
                    }
                }
                &:nth-child(2) {
                    .tp-loop-wrap {
                        margin-bottom:50px;
                    }
                }
                h4 {
                    margin:0!important;
                    font-size:20px!important;
                }
                &:nth-child(4) {
                    margin:0;
                }
                &:nth-child(5) {
                    margin:-24px 0 0;
                }
                &:nth-child(6) {
                    margin:-24px 0 0;
                }
                &:last-child {
                    margin:150px 0 50px 0;
                    .tp-mask-wrap {
                        flex-flow:row wrap;
                        &:after {
                            content:'Scroll';
                            display:block;
                            flex:1 0 100%;
                            text-align:center;
                        }
                    }
                }
            }
        }
    }
}
.menuHidden {
    .gem-slideshow {
        .tp-revslider-mainul {
            > li {
                left:0!important;
            }
            
        }
    }
}

.gem-slideshow + .block-content.no-top-margin {
    position:relative;
    z-index:1;
    background-color:#222;
}

#rev_slider_1_1 > div.tp-carousel-wrapper > ul > li > div:nth-child(4) > div:after {
    display:none!important;
}

@media (max-width:978px) {
    #page.vertical-header #site-header-wrapper {
        position:fixed;
        top:0;
        right:0;
        min-height:0;
        background-color:#222!important;
    }
    .custom-top-container {
        position:fixed;
    }
    .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
        background-color:#fff;
    }
    .wpml-ls-legacy-dropdown .wpml-ls-item {
        height:50px;
        display:flex;
        align-items:center;
        justify-content:center;
        border-bottom:1px solid #ccc;
    }
    .wpml-ls-item-legacy-dropdown {
        height:30px!important;
        border:0!important;
    }
    .gem-slideshow + .block-content.no-top-margin {
        position:relative;
        z-index:1;
        background-color:#222!important;
        div.vc_align_right:nth-child(1) > figure:nth-child(1) > a:nth-child(1) > img:nth-child(1) {
            padding:0!important;
            border:0!important;
            display:block!important;
            margin:0 auto!important;
        }
    }
    div.vc_align_left:nth-child(1) > figure:nth-child(1) > a:nth-child(1) > img:nth-child(1),
    div.vc_align_left:nth-child(2) > figure:nth-child(1) > a:nth-child(1) > img:nth-child(1),
    div.vc_align_right:nth-child(2) > figure:nth-child(1) > a:nth-child(1) > img:nth-child(1) {
        padding:0!important;
        border:0!important;
        display:block!important;
        margin:0 auto!important;
    }
}

.active-revslide {
    .tp-parallax-wrap {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            opacity:0;
            margin:50px 0 0;
            transform:translate3d(0, 20px, 0);
        }
        &:nth-child(3) {
            animation:slider 9s ease 0s infinite;
        }
        &:nth-child(4) {
            animation:slider 9s ease 3s infinite;
        }
        &:nth-child(5) {
            animation:slider 9s ease 6s infinite;
        }
    }
}

@keyframes slider {
    0% {
        opacity:0;
        transform:translate3d(0, 20px, 0);
    }
    5% {
        opacity:1;
        transform:translate3d(0, 0, 0);
    }
    28% {
        opacity:1;
        transform:translate3d(0, 0, 0);
    }
    33% {
        opacity:0;
        transform:translate3d(0, -20px, 0);
    }
    100% {
        opacity:0;
        transform:translate3d(0, 20px, 0);
    }
}

@media (max-width:767px) {
    body {
        padding-top:63px;
    }
    .row {
        margin:0;
    }
    .quickfinder-item-box {
        padding:30px 0!important;
    }
    .headerFlexCenter {
	    height:237px;
	    margin:0;
    }
    #whychooseFleetMaster {
	    margin-top:0;
	    height:237px;
    }
    #download-image {
	    margin: 100px 0 0;
    }
}